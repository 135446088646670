@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");

  font-weight: normal;
}
@font-face {
  font-family: "poppins-light";
  src: local("poppins-light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Poppins;
  color: white;
}

.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}

.css-1qzevvg {
  display: none !important;
}
.pointer {
  cursor: pointer;
}

._carousel-wrapper_zvdee_1 ._carousel_zvdee_1 ._carousel-cell_zvdee_31 {
  color: white;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
}
