.navbar {
  background-color: #060b26;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}
.sidebar-icons {
  height: 8vh;
}

.nav-menu {
  background-color: #1c1c1c;
  z-index: 5;
  width: 100px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  padding-top: 2vh;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 0;
  list-style: none;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
